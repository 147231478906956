.card {
  @media screen and (max-width: 500px) {
    margin-right: 2rem;
    margin-left: 2rem;
  }
  height: 350px;
  width: 200px;
  border-radius: 10px;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.08), 0 0 6px rgba(0, 0, 0, 0.05);

  transition: all 0.5s ease;
  &:hover {
    transform: scale(1.05);
    background-color: #fafafa;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12), 0 4px 8px rgba(0, 0, 0, 0.06);
  }

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;

  .img {
    height: 150px;
    width: 100%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    cursor: pointer;
  }

  .title {
    font-size: 20px;
    max-width: 190px;
    font-family: "Myriad Pro";
    text-align: center;
    color: black;
    margin-top: 6px;
  }

  .category {
    font-size: 16px;
  }

  .price {
    font-size: 20px;
    margin-top: 8px;
    font-family: "Helvetica";
  }

  .details {
    color: white;
    background-color: black;
    transition: all 0.5s ease;
    font-weight: bold;
    width: 100%;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    height: 45px;
    cursor: pointer;
  }

  .fav {
    position: absolute;
    top: -20px;
    right: -20px;
    color: black;
    transition: all 0.5s ease;
    &:hover {
      color: #ffaaa4;
    }
    &.active {
      color: "#c06573";
    }
  }
}
