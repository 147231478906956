.addtitle {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 40px;
}
.Next {
  padding: 10px 35px;
  width: 365px;
  // margin-top: 2em;
  margin-top: 30px !important;
  color: #fff1e6 !important;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  font-size: 18px;
  font-family: sans-serif;
  transition: all 0.5s;
  text-decoration: none;
  list-style: none;
  z-index: 5;
}

.Next:before {
  width: 100%;
  height: 100%;
  content: "";
  margin: auto;
  position: absolute;
  top: 0%;
  left: 0%;
  background: black;
  transition: all 0.5s;
  z-index: -1;
}
.Next:after {
  width: 100%;
  height: 100%;
  content: "";
  margin: auto;
  position: absolute;
  top: 0%;
  left: 0%;
  background: black;
  transition: all 0.5s;
  z-index: -1;
}
.Next:hover {
  color: black !important;
  background-color: white !important;
}
.Next:hover:before {
  transform: rotateX(90deg);
}
.Next:hover:after {
  transform: rotateY(90deg);
}
.stripe {
  color: #000000;
  cursor: pointer;

  transition: "all 1s ease";
}
// .stripe:hover{

//   color: #e41414;

// }
.cashdelivery {
  color: #1f201e;
  cursor: pointer;
}

// .cashdelivery:hover{
//   color: #e97910;
//   cursor: pointer;

// }
