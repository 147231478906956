.btn-outline-primary {
  border-color: #99bfd1;
  color: #99bfd1;
  &:hover {
    transition: 0.3s;
    border-color: #99bfd1;
    background-color: #99bfd1;
    color: white;
  }
}
.btn-outline-primary1 {
  border-color: #ffca9f;
  color: #ffca9f;
  font-size: 1.5em;
  font-weight: bold;
  &:hover {
    transition: 0.3s;
    border-color: #ffca9f;
    background-color: #ffca9f;
    color: white !important;
  }
}
.btn-outline-success1 {
  border-color: #25d366;
  font-size: 1.5em;
  font-weight: bold;
  color: #25d366;
  &:hover {
    transition: 0.3s;
    color: white;
    border-color: #25d366;
    background-color: #25d366;
  }
}

@media only screen and (max-width: 920px) {
  .btn-outline-primary {
    font-size: 1em;
  }
  .btn-outline-primary1 {
    font-size: 1em;
  }
  .btn-outline-success1 {
    font-size: 1em;
  }
}

@media only screen and (max-width: 635px) {
  .btn-outline-primary {
    font-size: 0.8em;
  }
  .btn-outline-primary1 {
    font-size: 0.8em;
  }
  .btn-outline-success1 {
    font-size: 0.8em;
  }
}
