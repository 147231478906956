.swiper-container {
  width: 83%;
  /* height: 400px; */
}

.swiper-slide {
  text-align: center;
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  /* display: block; */
  /* width: 100%; */
  height: 280px;
  /* width: 250px; */
  object-fit: cover;
  border: 0px solid red;
}

.swiper-button-next,
.swiper-button-prev {
  color: black;
  transition: "all 1s ease";
}

.swiper-button-next:hover,
.swiper-button-prev:hover {
  color: #da7685;
}

@media only screen and (max-width: 1300px) {
  .swiper-container {
    width: 94%;
  }
}

@media only screen and (max-width: 420px) {
  .swiper-slide img {
    height: 250px;
    /* margin-top: -40px; */
  }
  .mySwiper {
    width: 100%;
    margin-top: 2rem;
  }
}

.swiperHeight {
  border: 0px solid green;
  height: 480px;
}

FavoriteIcon:hover {
  background-color: blue;
  color: blue;
}

.view_gallery2 {
  transition: all 0.2s ease-in;
}

.view_gallery3 {
  transition: all 0.2s ease-in;
}

.FavIcon {
  right: 30px !important;
}

.imageWidth {
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
}

@media only screen and (max-width: 460px) {
  .FavIcon {
    right: 80px !important;
  }
}

@media only screen and (min-width: 1024px) {
  .FavIcon {
    right: 320px !important;
  }
}

@media only screen and (min-width: 768px) {
  .FavIcon {
    right: 210px !important;
  }
}
.dialogheart {
  background-color: #c9e4e7 !important;
}

/* @media only screen and (max-width: 450px) {
  .imageWidth {
    width: 120px !important;
  }
} */

@media only screen and (max-width: 600px) {
  .swiper-slide img {
    height: 220px;
    width: 147px;
    /* margin-top: -40px; */
  }

  .swiperHeight {
    border: 0px solid green;
    height: 400px;
  }
}

@media only screen and (max-width: 500px) {
  .swiper-slide img {
    height: 200px;
    width: 135px;
    /* margin-top: -40px; */
  }
  .swiper-button-prev {
    margin-left: -12px;
  }
  .swiper-button-next {
    margin-right: -12px;
  }
}

@media only screen and (max-width: 455px) {
  .swiper-slide img {
    height: 180px;
    width: 121px;
    /* margin-top: -40px; */
  }
  .swiper-button-prev {
    margin-left: -10px;
  }
  .swiper-button-next {
    margin-right: -10px;
  }
}

/* @media only screen and (max-width: 360px) {
  .swiper-slide img {
    height: 160px;
    width: 108px;

  }
  .swiper-button-prev {
    margin-left: -12px;
  }
  .swiper-button-next {
    margin-right: -12px;
  }
}

@media only screen and (max-width: 320px) {
  .swiper-slide img {
    height: 150px;

  }
  .swiper-button-prev {
    margin-left: -12px;
  }
  .swiper-button-next {
    margin-right: -12px;
  }
} */
