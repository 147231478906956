.root {
  flex-grow: 1 !important;
}
@media only screen and (max-width: 768px) {
  .noproductfound {
    margin: 0 auto !important;
  }
}
.noproductfound {
  position: relative !important;
  /* margin-right: 100px !important; */
  margin: 0 auto !important;
  margin-top: 20% !important ;
  /*text-align: center !important;*/
  justify-content: center !important;
}
