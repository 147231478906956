#updatePassbtn {
    background-color: black;
    color: rgb(255, 255, 255);
    transition: all 0.5 ease;
}

#updatePassbtn:hover {
    background-color: #ffa28b;
    color: rgb(0, 0, 0);
    font-weight: bold;
}

#changePassbtn {
    background-color: rgb(255, 255, 255);
    color: rgb(0, 0, 0);

    transition: all 0.5 ease;
}

#changePassbtn:hover {
    background-color: #ffa28b;
    color: rgb(255, 255, 255);
    border: transparent;
    font-weight: bold;
}

#updatePasswordbtn {
    background-color: rgb(0, 0, 0);
    color: rgb(255, 255, 255);

    transition: all 0.5 ease;
}

#updatePasswordbtn :hover {
    background-color: #ffa28b;
    color: rgb(255, 255, 255);
    border: transparent;
    font-weight: bold;
}
