.addtitle{
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 40px;
}

.Next{
  padding: 10px 35px;
  width: 365px;
  margin-top: 2em;
  color: #FFF1E6;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  font-size: 18px;
  font-family: sans-serif;
  transition: all .5s;
  text-decoration: none;
  list-style: none;
  z-index: 5;
}

.Nextbtnaddress{
  padding: 15px 15px;
  max-width: 365px;
  width:100%;
  margin-top: 20px;
  color: #FFF1E6;
  cursor:initial !important;
  position: relative;
  overflow: hidden;
  font-size: 18px;
  font-family: sans-serif;
  transition: all .5s;
  text-decoration: none;
  list-style: none;
  z-index: 5;
}

.Nextbtnaddress:after{
  width: 100%;
  height: 100%;
  content: '';
  margin: auto;
  position: absolute;
  top:  0%;
  left: 0%;
  background: black;
  transition: all .5s;
  z-index: -1;
}

.Nextbtnaddress:hover{
  color: black;
  background-color: white;
}

.Nextbtnaddress:hover:before{
  transform: rotateX(90deg);
}
.Nextbtnaddress:hover:after{
  transform: rotateY(90deg);
}

@media only screen and (max-width: 768px) {
  .Nextbtnaddress{
    padding: 15px 15px;
    max-width: 365px;
    width:100%;
    margin-top: -20px;
    color: #FFF1E6;
    cursor:initial !important;
    position: relative;
    overflow: hidden;
    font-size: 15px;
    font-family: sans-serif;
    transition: all .5s;
    text-decoration: none;
    list-style: none;
    z-index: 5;
  }
  
  .Nextbtnaddress:after{
    width: 100%;
    height: 100%;
    content: '';
    margin: auto;
    position: absolute;
    top:  0%;
    left: 0%;
    background: black;
    transition: all .5s;
    z-index: -1;
  }

  .Nextbtnaddress:before{
    width: 100%;
    height: 100%;
    content: '';
    margin: auto;
    position: absolute;
    top:  0%;
    left: 0%;
    background: black;
    transition: all .5s;
    z-index: -1;
  }
  
  .Nextbtnaddress:hover{
    color: black;
    background-color: white;
  }
  
  .Nextbtnaddress:hover:before{
    transform: rotateX(90deg);
  }
  .Nextbtnaddress:hover:after{
    transform: rotateY(90deg);
  }
}

.NextLogin{
  padding: 10px 35px;
  width: 365px;
  margin-top: 2em;
  color: #FFF1E6;
  cursor:initial !important;
  position: relative;
  overflow: hidden;
  font-size: 18px;
  font-family: sans-serif;
  transition: all .5s;
  text-decoration: none;
  list-style: none;
  z-index: 5;
}


.NextLogin:before{
  width: 100%;
  height: 100%;
  content: '';
  margin: auto;
  position: absolute;
  top:  0%;
  left: 0%;
  background: black;
  transition: all .5s;
  z-index: -1;
}

.NextLogin:after{
  width: 100%;
  height: 100%;
  content: '';
  margin: auto;
  position: absolute;
  top:  0%;
  left: 0%;
  background: black;
  transition: all .5s;
  z-index: -1;
}

.NextLogin:hover{
  color: black;
  background-color: white;
}

.NextLogin:hover:before{
  transform: rotateX(90deg);
}
.NextLogin:hover:after{
  transform: rotateY(90deg);
}

.Next:before{
  width: 100%;
  height: 100%;
  content: '';
  margin: auto;
  position: absolute;
  top:  0%;
  left: 0%;
  background: black;
  transition: all .5s;
  z-index: -1;
}

.Next:before{
  width: 100%;
  height: 100%;
  content: '';
  margin: auto;
  position: absolute;
  top:  0%;
  left: 0%;
  background: black;
  transition: all .5s;
  z-index: -1;
}

.Next:after{
  width: 100%;
  height: 100%;
  content: '';
  margin: auto;
  position: absolute;
  top:  0%;
  left: 0%;
  background: black;
  transition: all .5s;
  z-index: -1;
}

.Next:hover{
  color: black;
  background-color: white;
}

.Next:hover:before{
  transform: rotateX(90deg);
}
.Next:hover:after{
  transform: rotateY(90deg);
}
