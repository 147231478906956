.categoryTitle {
  display: flex;
  margin-top: 60px;
  margin-bottom: 20px;
  /* font-family: "FreeStyle Script " !important; */
  font-family: "Dancing Script", cursive !important;
  font-size: 80px;
  text-align: center;
}

@media only screen and (max-width: 540px) {
  .homeContainer {
    margin-top: 120px !important;
  }
}
@media only screen and (max-width: 768px) and (min-width: 541px) {
  .homeContainer {
    margin-top: 109px !important;
  }
}
@media only screen and (max-width: 955px) and (min-width: 769px) {
  .homeContainer {
    margin-top: 98px !important;
  }
}
@media only screen and (max-width: 1000px) and (min-width: 956px) {
  .homeContainer {
    margin-top: 115px !important;
  }
}
.homeContainer {
  margin-top: 115px;
}

@media only screen and (max-width: 600px) {
  .categoryTitle {
    margin-bottom: -10px !important;
  }
}

@media only screen and (max-width: 455px) {
  .categoryTitle {
    margin-bottom: -20px !important;
  }
}

@media only screen and (max-width: 360px) {
  .categoryTitle {
    margin-bottom: -60px !important;
  }
}

@media only screen and (max-width: 359px) {
  .MuiTypography-h2 {
    font-size: 2.75rem !important;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 300;
    line-height: 1.2;
    letter-spacing: -0.00833em;
  }
}

.menwomenkid {
  margin-left: 20px;
  position: relative;
  display: flex;
}

.imgs {
  display: flex;
}

.imagesKid {
  position: relative;
  width: 100%;
  max-width: 400px;
}

/* .collapse-css-transition {
    transition: height 300ms cubic-bezier(0.4, 0, 0.2, 1) !important;
    overflow: hidden;
} */

@media only screen and (max-width: 1250px) {
  .imagesKid {
    position: relative !important;
    width: 100% !important;
    max-width: 300px !important;
  }
}

@media only screen and (max-width: 599px) {
  .imagesKid {
    position: relative !important;
    width: 100% !important;
    max-width: 100% !important;
  }
}

@media only screen and (max-width: 570px) {
  .imagesKid {
    position: relative !important;
    width: 100% !important;
    max-width: 100% !important;
  }
}

.imagesMen {
  position: relative !important;
  width: 100% !important;
  max-width: 400px !important;
  transition: all 0.5s ease;
  border-radius: 16px;
  filter: brightness(80%);
}

.imagesMen:hover {
  filter: brightness(100%);
}

@media only screen and (max-width: 1250px) {
  .imagesMen {
    position: relative !important;
    width: 100% !important;
    /* max-width: 300px !important; */
  }
}

@media only screen and (max-width: 599px) {
  .imagesMen {
    position: relative !important;
    width: 100% !important;
    max-width: 100% !important;
  }
}

@media only screen and (max-width: 570px) {
  .imagesMen {
    position: relative !important;
    width: 100% !important;
    max-width: 100% !important;
    margin-bottom: 20px;
  }
}

.imagesWomen {
  width: 100% !important;
  max-width: 400px !important;
}

@media only screen and (max-width: 1250px) {
  .imagesWomen {
    position: relative !important;
    width: 100% !important;
    max-width: 300px !important;
  }
}

@media only screen and (max-width: 1000px) {
  .imagesWomen {
    position: relative !important;
    width: 100% !important;
    max-width: 200px !important;
    height: 200px !important;
  }
}

@media only screen and (max-width: 1000px) {
  .imagesMen {
    position: relative !important;
    width: 100% !important;
    /* max-width: 200px !important; */
    /* height: 200px !important; */
  }
}

@media only screen and (max-width: 599px) {
  .imagesWomen {
    position: relative !important;
    width: 100% !important;
    /* max-width: 100% !important; */
  }
}

@media only screen and (max-width: 570px) {
  .imagesWomen {
    position: relative !important;
    width: 100% !important;
    max-width: 100% !important;
  }
}

/* .container {
    display: flex !important;
    position: relative !important;
} */

@media only screen and (max-width: 570px) {
  .container {
    display: block !important;
    position: relative !important;
    margin-top: 50px;
    /* margin:0 auto !important; */
    /* margin-left: 50px; */
  }
}

.sectionsBtns {
  /* position: absolute; */
  background-color: rgba(104, 100, 98, 0.397) !important;
  max-width: 400px !important;
  width: 100%;
  border-radius: 0 !important;
  bottom: 0 !important;
  z-index: 5;
  transition: background-color 0.7s !important;
  color: rgb(255, 255, 255) !important;
  /* margin-bottom: 20px !important; */
  /*z-index: 3 !important; */
}

.sectionsBtns:hover {
  /* position: absolute; */
  background-color: rgb(255, 255, 255) !important;
  max-width: 400px !important;
  width: 100%;
  border-radius: 0 !important;
  bottom: 0 !important;
  z-index: 5;
  color: rgb(0, 0, 0) !important;
  /* margin-bottom: 20px !important; */
  /*z-index: 3 !important; */
}

@media only screen and (max-width: 570px) {
  .sectionsBtns {
    background-color: rgba(104, 100, 98, 0.397) !important;
    max-width: 100% !important;
    width: 100%;
    border-radius: 0 !important;
    bottom: 0 !important;
    z-index: 5;
  }
}

@media only screen and (max-width: 570px) {
  .catContainer {
    display: block !important;
  }
}

/* @media only screen and (max-width: 570px) {
    .sectionsBtns {
        background-color: rgba(255, 241, 230, 0.5);
        width: 100% !important;
        width: 300px !important;
        border-radius: 0 !important;
    }
} */

.image-container {
  position: relative;
  width: 100%;
  /* height: 300px; */
  max-width: 800px;
}

@media only screen and (max-width: 570px) {
  .image-container {
    margin-bottom: 20px;
  }
}

.image-container .txtContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% - 7px) !important;
  /* height: 100% !important; */
  display: none;
  color: #fff;
  display: block;
  background: rgba(0, 0, 0, 0.6);
}

.image-container2 {
  position: relative;
  width: 100%;
  /* height: 300px; */
  max-width: 800px;
}

@media only screen and (max-width: 570px) {
  .image-container2 {
    margin-bottom: 20px;
  }
}

.image-container2 .txtContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  /* height: 100%; */
  height: calc(100% - 7px) !important;
  display: none;
  color: #fff;
  display: block;
  background: rgba(0, 0, 0, 0.6);
}

@media only screen and (max-width: 786px) {
  .cat3 {
    font-size: 40px !important;
    text-align: center;
    margin-top: 20% !important;
  }
}

/* @media only screen and (max-width: 786px) {
    .cat3 {
        font-size: 35px !important;
    }
} */

/* 
@media (min-width:600px) and (max-width:800px){
    .cat3 {
        font-size: 100px !important;
    }
} */

@media only screen and (max-width: 570px) {
  .cat3 {
    font-size: 50px !important;
  }
}

/* .image-container .cat1 {
    display: block;
    background: rgba(0, 0, 0, .6);
} */

.cat1 {
  text-align: center;
  margin-top: 40% !important;
  font-size: 50px !important;
}

.cat2 {
  text-align: center;
  margin-top: 40% !important;
  font-size: 50px !important;
  /* margin: auto !important; */
}

.cat3 {
  text-align: center;
  margin-top: 20% !important;
  font-size: 50px !important;
  /* margin: auto !important; */
}

@media only screen and (max-width: 570px) {
  .containerInsta {
    display: block !important;
    width: 100%;
    max-width: 300px;
  }
}

.instapic1 {
  width: 100%;
  max-width: 300px !important;
}

@media only screen and (max-width: 650px) {
  .instapic1 {
    width: 100%;
    max-width: 200px !important;
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 570px) {
  .instapic1 {
    width: 100%;
    max-width: 300px !important;
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 650px) {
  .instapic1 {
    width: 100%;
    max-width: 200px;
    margin-bottom: 20px;
  }
}
