.pass-wrapper {
    position: relative;
    display: flex;
}

.fa-eye-slash {
    position: absolute;
    top: 38%;
    right: 16%;
}
.fa-eye-slash:hover {
    color: #d39890;
    cursor: pointer;
}

.fa-eye {
    position: absolute;
    top: 38%;
    right: 16%;
}
.fa-eye:hover {
    color: #d39890;
    cursor: pointer;
}
