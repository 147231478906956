.btnShop {
  color: white;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  font-size: 1.5rem;
  font-weight: bold;
  transition: all 0.5s;
  text-decoration: none;
  list-style: none;
  z-index: 5;
}

.btnShop1 {
  color: white;
  background: #d39890;
  cursor: pointer;
  border-radius: 0;
  padding: 1.25rem 1.25rem;
  position: relative;
  overflow: hidden;
  font-size: 1.5rem;
  font-weight: bold;
  transition: all 0.5s;
  text-decoration: none;
  list-style: none;
  z-index: 5;
}

.btnShop1:hover {
  color: #d39890;
  background: white;
}
/* 
.btnShop:before {
  width: 100%;
  height: 100%;
  content: "";
  margin: auto;
  position: absolute;
  top: 0%;
  left: 0%;
  background: #d39890;
  transition: all 0.5s;
  z-index: -1;
}
.btnShop:after {
  width: 100%;
  height: 100%;
  content: "";
  margin: auto;
  position: absolute;
  top: 0%;
  left: 0%;
  background: #d39890;
  transition: all 0.5s;
  z-index: -1;
} */
.btn:hover {
  color: #d39890;
  background: white;
}
/*.btn:hover:before {
  transform: rotateX(90deg);
  background: white;
}
.btn:hover:after {
  transform: rotateY(90deg);
  background: white;
} */
.btn-primary1 {
  border: 1px solid #d39890;
  background-color: #d39890;
}
.btn-primary1:hover {
  color: #d39890;
  background: white;
  border: 1px solid #d39890;
}
/*.btn-primary1:hover:before {
  transform: rotateX(90deg);
  background: white;
}
.btn-primary1:hover:after {
  transform: rotateY(90deg);
  background: white;
} */
