.imageContainer {
  margin-bottom: 20px;
  position: relative;
}
.imageContainer .textContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% - 7px);
  display: none;
  color: #fff;
  display: block;
  background: rgba(0, 0, 0, 0.6);
}

.contactText {
  font-size: 30px;
  font-family: "Freestyle Script";
}

@media only screen and (min-width: 300px) {
  .contactText {
    font-size: 30px;

    margin-left: 26%;
    margin-top: 22%;
  }
}

@media only screen and (min-width: 400px) {
  .contactText {
    font-size: 30px;

    margin-left: 28%;
    margin-top: 22%;
  }
}

@media only screen and (min-width: 600px) {
  .contactText {
    font-size: 70px;

    margin-left: 34%;
    margin-top: 22%;
  }
}

@media only screen and (min-width: 850px) {
  .contactText {
    font-size: 80px;

    margin-left: 38%;
    margin-top: 22%;
  }
}

@media only screen and (min-width: 1280px) {
  .contactText {
    font-size: 70px;

    margin-left: 32%;
    margin-top: 22%;
  }
}

@media only screen and (min-width: 1400px) {
  .contactText {
    font-size: 90px;

    margin-left: 32%;
    margin-top: 22%;
  }
}

@media only screen and (min-width: 1700px) {
  .contactText {
    font-size: 100px;

    margin-left: 30%;
    margin-top: 22%;
  }
}

@media only screen and (min-width: 2000px) {
  .contactText {
    font-size: 110px;

    margin-left: 30%;
    margin-top: 20%;
  }
}

.btnSubmit {
  margin-bottom: 50px !important;
  background-color: black !important;
  color: white !important;
  padding-left: 50px !important;
  padding-right: 50px !important;
  transition: all 0.5s ease;
  /* margin: auto !important; */
}

.btnSubmit:hover {
  margin-bottom: 50px !important;
  background-color: #ffa28b !important;
  color: rgb(0, 0, 0) !important;
  padding-left: 50px !important;
  padding-right: 50px !important;
  /* margin: auto !important; */
}

@media only screen and (max-width: 500px) {
  .mapmobile {
    padding-right: 30px !important;
  }
}
