.modalpadding {
  margin-top: -200px !important;
}

.modal-content {
  margin-top: 100px !important;
}

.modal-title {
  font-size: 20px;
}
/* .fa-whatsapp {
  color: #808080;
}
.fa-whatsapp:hover {
  color: #25d366;
} */
