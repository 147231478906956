.float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 25px;
  right: 25px;
  background-color: #25d366;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 35px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
}

.float:visited {
  color: #fff;
}

.float:hover {
  color: #fff;
}

.my-float {
  margin-top: 13px;
}

@media screen and (max-width: 600px) {
  .float {
    position: fixed;
    width: 50px;
    height: 50px;
    bottom: 15px;
    right: 15px;
    background-color: #25d366;
    color: #fff;
    border-radius: 50px;
    text-align: center;
    font-size: 30px;
    box-shadow: 2px 2px 3px #999;
    z-index: 100;
  }
  .my-float {
    margin-top: 10px;
  }
}

.scrollToTopBtn {
  background-color: black;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 18px;
  line-height: 48px;
  width: 48px;

  /* place it at the bottom right corner */
  position: fixed;
  bottom: 20px;
  right: 20px;
  /* keep it at the top of everything else */
  z-index: 100;
  /* hide with opacity */
  opacity: 0;
  /* also add a translate effect */
  transform: translateY(100px);
  /* and a transition */
  transition: all 0.5s ease;
}

.showBtn {
  opacity: 1;
  transform: translateY(0);
}
