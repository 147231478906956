.ch-item {
  width: 85%;
  height: 100%;
  margin-left: 20px;
  border-radius: 50%;
  position: relative;

  cursor: pointer;
}

li {
  list-style-type: none;
}

.ch-info-wrap {
  width: 180px;
  height: 180px;
  border-radius: 50%;
  perspective: 800px;
  transition: all 0.4s ease-in-out;
  background: #ffca9f;
  box-shadow: 0 0 0 10px #ffca9f98, inset 0 0 3px #ffca9f62;
}
.ch-info {
  width: 180px;
  height: 180px;
  border-radius: 50%;
  transition: all 0.4s ease-in-out;
  transform-style: preserve-3d;
}
.ch-info > div {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-position: center center;
  backface-visibility: hidden;
}
.ch-info .ch-info-back {
  transform: rotate3d(0, 1, 0, 180deg);
  background: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 0 10px #ffca9f98, inset 0 0 3px #ffca9f62;
}
.ch-img-1 {
  background-image: url(../../images/11.png);
  background-repeat: no-repeat;
  background-size: 130px 70px;
}

.ch-img-Pyjamas {
  background-image: url(../../images/Pajamas.png);
  background-repeat: no-repeat;
  background-size: 130%;
  justify-content: center;
}

.ch-img-Sport-Wear {
  background-image: url(../../images/sportwear.png);
  background-repeat: no-repeat;
  background-size: 100%;
}
.ch-img-Underwear {
  background-image: url(../../images/underwears.png);
  background-repeat: no-repeat;
  background-size: 100%;
}
.ch-img-Robe {
  background-image: url(../../images/Robe.png);
  background-repeat: no-repeat;
  background-size: 50%;
}
.ch-img-Slipper {
  background-image: url(../../images/Slipper.png);
  background-repeat: no-repeat;
  background-size: 150%;
}
.ch-img-Overall {
  background-image: url(../../images/Overall.png);
  background-repeat: no-repeat;
  background-size: 150%;
}
.ch-img-Towels {
  background-image: url(../../images/Towels.png);
  background-repeat: no-repeat;
  background-size: 120%;
}
.ch-img-Pillows {
  background-image: url(../../images/Pillow.png);
  background-repeat: no-repeat;
  background-size: 130%;
}
.ch-img-Blankets {
  background-image: url(../../images/Blankets.png);
  background-repeat: no-repeat;
  background-size: 100%;
}
.ch-img-Crib-Set {
  background-image: url(../../images/Cribset.png);
  background-repeat: no-repeat;
  background-size: 130%;
}
.ch-img-Gloves {
  background-image: url(../../images/gloves.png);
  background-repeat: no-repeat;
  background-size: 150%;
}
.ch-img-Bibs {
  background-image: url(../../images/Bibs.png);
  background-repeat: no-repeat;
  background-size: 80%;
}

.ch-info h3 {
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 15px;
  width: 100%;
  height: 100%;
  /* border:2px solid rgba(255, 255, 255, 0); */
  margin: 20 25px;
  /* padding: 40px 0 0 0; */
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Open Sans", Arial, sans-serif;
  text-shadow: 0 0 1px #fff, 0 1px 2px rgba(0, 0, 0, 0.3);
}

.ch-info p {
  color: #fff;
  padding: 5px 5px;
  font-style: italic;
  margin: 0 20px;
  margin-top: -60px;
  font-size: 12px;
  border-top: 1px solid rgba(146, 240, 69, 0.5);
}

.ch-info p a {
  display: block;
  color: rgba(49, 245, 42, 0.7);
  font-style: normal;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 9px;
  letter-spacing: 1px;
  padding-top: 4px;

  font-family: "Open Sans", Arial, sans-serif;
}

.ch-info p a:hover {
  color: rgba(255, 242, 34, 0.8);
}
.ch-item:hover .ch-info-wrap {
  box-shadow: 0 0 0 0 #ffca9f98, inset 0 0 3px #ffca9f62;
}

.ch-item:hover .ch-info {
  transform: rotate3d(0, 1, 0, -180deg);
}

@media only screen and (min-width: 1500px) {
  .ch-item {
    margin-left: 30%;
  }
  .ch-img-1 {
    background-size: 130px 70px;
  }
}

@media only screen and (max-width: 960px) {
  .ch-item {
    margin-left: 22%;
  }
}

@media only screen and (max-width: 685px) {
  .ch-item {
    margin-left: 15%;
  }

  .ch-info-wrap {
    width: 130px !important;
    height: 130px !important;
  }
  .ch-info {
    width: 130px !important;
    height: 130px !important;
  }
}

@media only screen and (max-width: 600px) {
  .ch-item {
    margin-left: 15%;
  }

  .ch-info-wrap {
    width: 130px !important;
    height: 130px !important;
  }
  .ch-info {
    width: 130px !important;
    height: 130px !important;
  }
}

@media only screen and (max-width: 530px) {
  .ch-item {
    margin-left: 19%;
  }

  .ch-info-wrap {
    width: 90px !important;
    height: 90px !important;
  }
  .ch-info {
    width: 90px !important;
    height: 90px !important;
  }
}

@media only screen and (max-width: 400px) {
  .ch-item {
    margin-left: 20%;
  }

  .ch-info-wrap {
    width: 70px !important;
    height: 70px !important;
    box-shadow: 0 0 0 5px #ffca9f98, inset 0 0 3px #ffca9f62;
  }
  .ch-info {
    width: 70px !important;
    height: 70px !important;
  }
  .ch-info h3 {
    font-size: 6px;
  }
}

@media only screen and (max-width: 430px) {
  .ch-info-wrap {
    box-shadow: 0 0 0 5px #ffca9f98, inset 0 0 3px #ffca9f62;
  }
  .ch-info h3 {
    font-size: 6px;
  }
}

@media only screen and (max-width: 360px) {
  .ch-info h3 {
    font-size: 6px;
  }
}
