.navItems:hover li{
    color: grey;
}

.navItems::after li{
    color: grey;
}

.font{
    font-family: 'Roboto', sans-serif !important;
}