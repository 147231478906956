/*	Reset & General
---------------------------------------------------------------------- */
.product {
  width: 306px;
  height: 471px;
  transform-style: preserve-3d;
  transition: transform 5s;
}
.product-front img {
  width: 100%;
}
.product-front {
  width: 305px;
  height: 470px;
  background: #fff;
  position: absolute;
  left: -5px;
  top: -5px;
  -webkit-transition: all 100ms ease-out;
  transition: all 100ms ease-out;
}
.make3D.animate,
.make3D.animate .product-front,
div.large {
  top: 0px;
  left: 0px;
  -webkit-transition: all 100ms ease-out;
  -moz-transition: all 100ms ease-out;
  -o-transition: all 100ms ease-out;
  transition: all 100ms ease-out;
}
.make3D {
  width: 295px;
  height: 460px;
  position: absolute;
  top: 10px;
  left: 10px;
  overflow: hidden;
  transform-style: preserve-3d;
  -webkit-transition: 100ms ease-out;
  -moz-transition: 100ms ease-out;
  -o-transition: 100ms ease-out;
  transition: 100ms ease-out;
}
.make3D.animate {
  top: 5px;
  left: 5px;
  box-shadow: 0px 5px 31px -1px rgba(0, 0, 0, 0.15);
  -webkit-transition: 100ms ease-out;
  -moz-transition: 100ms ease-out;
  -o-transition: 100ms ease-out;
  transition: 100ms ease-out;
}
div.large .make3D {
  top: 0;
  left: 0;
  width: 315px;
  height: 480px;
  -webkit-transition: 300ms ease-out;
  -moz-transition: 300ms ease-out;
  -o-transition: 300ms ease-out;
  transition: 300ms ease-out;
}
.large div.make3D {
  box-shadow: 0px 5px 31px -1px rgba(0, 0, 0, 0);
}
.stats-container {
  background: #fff;
  position: absolute;
  top: 340px;
  left: 0;
  width: 313px;
  height: 200px;
  padding: 24px 40px 35px 32px;
  -webkit-transition: all 200ms ease-out;
  -moz-transition: all 200ms ease-out;
  -o-transition: all 200ms ease-out;
  transition: all 200ms ease-out;
}
.make3D.animate .stats-container {
  top: 270px;
  -webkit-transition: all 200ms ease-out;
  -moz-transition: all 200ms ease-out;
  -o-transition: all 200ms ease-out;
  transition: all 200ms ease-out;
}
.stats-container .product_name {
  font-size: 18px;
  padding-bottom: 0.5em;
  color: #393c45;
  font-family: "Open Sans Light";
  font-weight: 700;
}
.stats-container p {
  font-size: 15px;
  color: #b1b1b3;
  padding: 2px 0 20px 0;
}
.stats-container .product_price {
  margin-top: 0.5em;
  font-size: 18px;
  color: #393c45;
  font-family: "Open Sans Light";
  font-weight: 700;
}
.image_overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff1e6;
  opacity: 0;
}
.make3D.animate .image_overlay {
  opacity: 0.65;
  -webkit-transition: all 200ms ease-out;
  -moz-transition: all 200ms ease-out;
  -o-transition: all 200ms ease-out;
  transition: all 200ms ease-out;
}
.product-options {
  padding: 0;
  margin-top: 1em;
}
.product-options strong {
  font-weight: 700;
  color: #393c45;
  font-size: 14px;
}
.colors {
  margin-left: -0.5em;
  margin-top: -1em;
}
.add_to_cart {
  position: absolute;
  top: 80px;
  left: 50%;
  width: 152px;
  font-size: 15px;
  margin-left: -78px;
  border-radius: 3px;
  background-color: black;
  color: #fff1e6;
  text-align: center;
  text-transform: uppercase;
  font-weight: 700;
  padding: 10px 0;
  opacity: 0;
}
.add_to_cart:hover {
  background: white;
  color: black;
  cursor: pointer;
}
.make3D.animate .add_to_cart {
  opacity: 1;
  -webkit-transition: all 200ms ease-out;
  -moz-transition: all 200ms ease-out;
  -o-transition: all 200ms ease-out;
  transition: all 200ms ease-out;
}
.view_gallery {
  position: absolute;
  top: 144px;
  left: 50%;
  width: 152px;
  font-size: 15px;
  margin-left: -78px;
  // background: #c9e4e7;
  border-radius: 16px;
  border: 2px solid;
  border-color: #000;
  background-color: #000;
  color: white;
  text-align: center;
  text-transform: uppercase;
  font-weight: 700;
  padding: 10px 0;
  opacity: 0;
  -webkit-transition: all 200ms ease-out;
  -moz-transition: all 200ms ease-out;
  -o-transition: all 200ms ease-out;
  transition: all 200ms ease-out;
}

.view_gallery:hover {
  background: #ffca9f;
  color: black;
  border-color: #ffca9f;
  cursor: pointer;
}

@media (max-width: 600px) {
  .view_gallery {
    position: absolute;
    width: 152px;
    top: 0px !important;
    left: 50%;
    height: 280px !important;
    font-size: 15px;
    margin-left: -78px;
    background: transparent;
    border-radius: 3px;
    color: transparent;
    text-align: center;
    text-transform: uppercase;
    font-weight: 700;
    padding: 10px 0;
    opacity: 0;
    -webkit-transition: all 200ms ease-out;
    -moz-transition: all 200ms ease-out;
    -o-transition: all 200ms ease-out;
    transition: all 200ms ease-out;
  }
}

.view_gallery2 {
  position: relative;
  margin-top: 10px;
  text-align: left;
  font-size: 15px;

  border-radius: 3px;
  color: black;

  text-transform: uppercase;
  font-weight: 700;
}
.view_gallery2:hover {
  // background: white;
  color: #c9e4e7;
  cursor: pointer;
}

.view_gallery:hover {
  background: #ffca9f;
  color: black;
  cursor: pointer;
}

.view_gallery3 {
  position: absolute;
  top: 0px;
  height: 90%;
  width: 80%;
  text-align: left;
  font-size: 15px;
  border-radius: 3px;
  color: black;
  opacity: 0;
  text-transform: uppercase;
  font-weight: 700;
}
.view_gallery3:hover {
  // background: white;
  color: #c9e4e7;
  cursor: pointer;
}

.make3D.animate .view_gallery {
  opacity: 1;
  -webkit-transition: all 200ms ease-out;
  -moz-transition: all 200ms ease-out;
  -o-transition: all 200ms ease-out;
  transition: all 200ms ease-out;
}
div.colors div {
  margin-top: 3px;
  width: 15px;
  height: 15px;
  margin-right: 5px;
  float: left;
}
div.colors div span {
  width: 15px;
  height: 15px;
  display: block;
  border-radius: 50%;
}
div.colors div span:hover {
  width: 17px;
  height: 17px;
  margin: -1px 0 0 -1px;
}
div.c-blue span {
  background: #6e8cd5;
}
div.c-red span {
  background: #f56060;
}
div.c-green span {
  background: #44c28d;
}
div.c-white span {
  background: #fff;
  width: 14px;
  height: 14px;
  border: 1px solid #e8e9eb;
}
div.shadow {
  width: 335px;
  height: 520px;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  display: none;
  background: -webkit-linear-gradient(left, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.2));
  background: -o-linear-gradient(right, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.2));
  background: -moz-linear-gradient(right, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.2));
  background: linear-gradient(to right, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.2));
}
div.floating-cart .stats-container {
  display: none;
}
div.floating-cart .product-front {
  width: 100%;
  top: 0;
  left: 0;
}
#info {
  position: absolute;
  top: 20px;
  left: 676px;
  text-align: center;
  width: 413px;
}
.product.large {
  width: 639px;
  margin-bottom: 25px;
  overflow: hidden;
  -webkit-transition: all 500ms ease-in-out;
  -moz-transition: all 500ms ease-in-out;
  -ms-transition: all 500ms ease-in-out;
  -o-transition: all 500ms ease-in-out;
  transition: all 500ms ease-in-out;
}

/* ---------------- */
.floating-image-large {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
.info-large {
  display: none;
  position: absolute;
  top: 0;
  left: 0px;
  padding: 42px;
  width: 245px;
  height: 395px;
  -webkit-transition: all 500ms ease-out;
  -moz-transition: all 300ms ease-out;
  -ms-transition: all 300ms ease-out;
  -o-transition: all 300ms ease-out;
  transition: all 300ms ease-out;
}
.large .info-large {
  left: 310px;
  -webkit-transition: all 300ms ease-out;
  -moz-transition: all 300ms ease-out;
  -ms-transition: all 300ms ease-out;
  -o-transition: all 300ms ease-out;
  transition: all 300ms ease-out;
}

.info-large h4 {
  text-transform: uppercase;
  font-size: 28px;
  color: #000;
  font-weight: 400;
  padding: 0;
}
.price-big {
  font-size: 34px;
  font-weight: 600;
  color: #5ff7d2;
  margin-top: 21px;
}
.price-big span {
  color: #d0d0d0;
  font-weight: 400;
  text-decoration: line-through;
}

.add-cart-large {
  border: 3px solid #000;
  font-size: 17px;
  background: #fff;
  text-transform: uppercase;
  font-weight: 700;
  padding: 10px;
  font-family: "Open Sans", sans-serif;
  width: 246px;
  margin-top: 38px;
  -webkit-transition: all 200ms ease-out;
  -moz-transition: all 200ms ease-out;
  -ms-transition: all 200ms ease-out;
  -o-transition: all 200ms ease-out;
  transition: all 200ms ease-out;
}
.add-cart-large:hover {
  color: #5ff7d2;
  border-color: #5ff7d2;
  -webkit-transition: all 200ms ease-out;
  -moz-transition: all 200ms ease-out;
  -ms-transition: all 200ms ease-out;
  -o-transition: all 200ms ease-out;
  transition: all 200ms ease-out;
  cursor: pointer;
}
.info-large h3 {
  letter-spacing: 1px;
  color: #262626;
  text-transform: uppercase;
  font-size: 14px;
  clear: left;
  margin-top: 20px;
  font-weight: 700;
  margin-bottom: 3px;
}

.colors-large {
  margin-bottom: 38px;
}
.colors-large li {
  float: left;
  list-style: none;
  margin-right: 7px;
  width: 16px;
  height: 16px;
}
.colors-large li a {
  float: left;
  width: 16px;
  height: 16px;
  border-radius: 50%;
}
.colors-large li a:hover {
  width: 19px;
  height: 19px;
  position: relative;
  top: -1px;
  left: -1px;
}

.sizes-large span {
  font-weight: 600;
  color: #b0b0b0;
}
.sizes-large span:hover {
  color: #000;
  cursor: pointer;
}

.product.large:hover {
  box-shadow: 0px 5px 31px -1px rgba(0, 0, 0, 0.15);
}
.stats-container .subproduct_name {
  font-family: "Montserrat";
  font-weight: 700;
  font-size: 18px;
}
@media only screen and (max-device-width: 480px) {
  .product {
    height: 280px;
    width: 180px;
    // margin-left: 10% !important;
  }
  .make3D {
    height: 280px;
    width: 180px;
    position: absolute;
    overflow: hidden;
    transform-style: preserve-3d;
    transition: 100ms ease-out;
    left: -4px;
  }
  .product-front {
    width: 175px;
  }
  .stats-container {
    top: 170px;
    left: 0;
    width: 175px;
    height: 200px;
    padding: 15px 4px 35px 17px;
  }
  .stats-container .product_name {
    font-size: 14px;
  }
  .stats-container .subproduct_name {
    font-size: 14px;
  }
  .stats-container .product_price {
    font-size: 12px;
  }
}
@media (device-width: 1024px) {
  .product {
    width: 196px;
    height: 461px;
    padding-right: 50px;
  }
  .make3D {
    width: 230px;
    height: 435px;
  }
  .product-front {
    width: 230px;
    height: 435px;
  }
  .stats-container {
    top: 325px;
    left: 0;
    width: 303px;
  }
  .stats-container .product_name {
    font-size: 16px;
  }
  .stats-container .subproduct_name {
    font-size: 16px;
  }
  .stats-container .product_price {
    font-size: 16px;
  }
  #favorite {
    position: absolute;
    top: 0.8em !important;
    right: 20px !important;
    z-index: 200;
  }
}
@media (device-width: 768px) {
  #favorite {
    position: absolute;
    top: 0.8em !important;
    right: 20px !important;
    z-index: 200;
  }
}
@media (device-width: 540px) {
  .product {
    width: 196px;
    height: 461px;
    padding-right: 50px;
  }
  .make3D {
    width: 230px;
    height: 435px;
  }
  .product-front {
    width: 230px;
    height: 435px;
  }
  .stats-container {
    top: 325px;
    left: 0;
    width: 303px;
  }
  .stats-container .product_name {
    font-size: 16px;
  }
  .stats-container .subproduct_name {
    font-size: 16px;
  }
  .stats-container .product_price {
    font-size: 16px;
  }
  #favorite {
    position: absolute;
    top: 0.8em !important;
    right: 20px !important;
    z-index: 200;
  }
}
@media (device-width: 320px) {
  .product {
    height: 270px;
    width: 170px;
  }
  .make3D {
    height: 270px;
    width: 170px;
    position: absolute;
    overflow: hidden;
    transform-style: preserve-3d;
    transition: 100ms ease-out;
    left: -4px;
  }
  .product-front {
    width: 165px;
  }
  .stats-container {
    top: 180px;
    left: 0;
    width: 165px;
    height: 190px;
    padding: 15px 4px 35px 17px;
  }
  .stats-container .product_name {
    font-size: 14px;
  }
  .stats-container .subproduct_name {
    font-size: 14px;
  }
  .stats-container .product_price {
    font-size: 14px;
  }
}
@media (max-device-width: 600px) {
  .make3D.animate,
  .make3D.animate .product-front,
  div.large {
  }
  .make3D.animate .stats-container {
    display: none;
  }
  .make3D.animate .add_to_cart {
    display: none;
  }
  .make3D.animate .view_gallery {
    // display: none;
  }
  .make3D.animate .image_overlay {
    display: none;
  }
}

@media screen and (max-width: 992px) {
  .onlydesktop {
    display: none;
  }
}

.dialogheart {
  background-color: #c9e4e7 !important;
}
