.table-header {
    display: flex !important;
    align-items: center !important;
    justify-content: space-between !important;
}

.product-image {
    width: 100px !important;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23) !important;
}

.status-instock {
    background: #c8e6c9 !important;
    color: #256029 !important;
    border-radius: 2px !important;
    padding: 0.25em 0.5rem !important;
    text-transform: uppercase !important;
    font-weight: 700 !important;
    font-size: 12px !important;
    letter-spacing: 0.3px !important;
}

.status-outofstock {
    background: #ffcdd2 !important;
    color: #c63737 !important;
    border-radius: 2px !important;
    padding: 0.25em 0.5rem !important;
    text-transform: uppercase !important;
    font-weight: 700 !important;
    font-size: 12px !important;
    letter-spacing: 0.3px !important;
}
