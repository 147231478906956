.fideleCart {
    display: flex !important;
    position: relative !important;

    /* margin-left: 20px !important; */
    /* margin: 0 auto !important; */
    /* align-items: center; */
}

@media only screen and (max-width: 1024px) {
    .cart_title {
        display: block !important;
        margin-left: 1rem !important;
    }
    .fideleCart {
        display: block !important;
        margin: 0 auto !important;
    }
}
