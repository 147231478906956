.list-group {
  max-height: 235px;
  width: auto;
  max-width: 300px;
  overflow: auto;
}

.roundedd {
  border-radius: 16px;
}

.roundedd1 {
  background-color: #ffca9f3d;
  color: #ffca9f;
  cursor: pointer;
  border-radius: 0 16px 16px 0;
}

.roundedd2 {
  border-radius: 16px;
}

.roundedd1:hover {
  color: black;
  transition: 0.5s;
}

.searchBarWidth {
  width: 400px;
  margin-left: 100px;
}

.listGroup {
  width: 400px;
  max-height: 235px;
  overflow: auto;
}

.hoveredItem:hover {
  background-color: rgb(255, 202, 159);
  transition: 0.5s;
}

@media screen and (max-width: 2688px) {
  .searchBarWidth {
    width: 450px;
    margin-left: 30%;
  }
  .listGroup {
    width: 365px;
  }
}

@media screen and (max-width: 2000px) {
  .searchBarWidth {
    width: 400px;
    margin-left: 250px;
  }
  .listGroup {
    width: 320px;
  }
}

@media screen and (max-width: 1500px) {
  .searchBarWidth {
    width: 400px;
    margin-left: 120px;
  }
  .listGroup {
    width: 320px;
  }
}

@media screen and (max-width: 1300px) {
  .searchBarWidth {
    width: 300px;
    margin-left: 60px;
  }
  .listGroup {
    width: 230px;
  }
}

@media screen and (max-width: 1100px) {
  .searchBarWidth {
    width: 230px;
    margin-left: 40px;
  }
  .listGroup {
    width: 165px;
  }
}

@media screen and (max-width: 955px) {
  .searchBarWidth {
    width: 330px;
    margin-left: 40px;
  }
  .listGroup {
    width: 255px;
  }
}

@media screen and (max-width: 630px) {
  .searchBarWidth {
    width: 230px;
    margin-left: 10px;
  }
  .listGroup {
    width: 165px;
  }
}

@media screen and (max-width: 500px) {
  .searchBarWidth {
    width: 220px;
    margin-left: 0px;
  }
  .listGroup {
    width: 158px;
  }
}

@media screen and (max-width: 432px) {
  .searchBarWidth {
    width: 220px;
    margin-left: -40px;
  }
}

@media screen and (max-width: 377px) {
  .searchBarWidth {
    width: 220px;
    margin-left: -50px;
    margin-right: -20px;
  }
}

@media screen and (max-width: 345px) {
  .searchBarWidth {
    width: 220px;
    margin-left: -50px;
    margin-right: -30px;
  }
}
