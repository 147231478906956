@mixin size($point) {
  @if $point==lg-device {
    @media (min-width: 1024px) and (max-width: 1600px) {
      @content;
    }
  } @else if $point==md-device {
    @media (min-width: 768px) and (max-width: 1023px) {
      @content;
    }
  } @else if $point==sm-device {
    @media (max-width: 767px) {
      @content;
    }
  }
}

.body {
  padding: 0px;
  margin: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;
  min-width: auto;

  @include size(sm-device) {
    align-items: baseline;
  }

  @include size(md-device) {
    align-items: baseline;
  }
}

.cart_wrapper {
  // max-width: 1200px;
  width: 100%;
  max-height: 800px;
  // display: flex;
  margin-top: 75px;
  flex-wrap: wrap;
  background-color: white;
  //box-shadow: 0 6px 32px rgba(0, 0, 0, 0.4);
  // margin: 5em 30px 30px 30px;
  overflow: hidden;
  // @include size(md-device) {
  //   max-height: 1000px;
  // }
  // @include size(sm-device) {
  //   max-height: unset;
  //   max-width: 100%;
  //   margin: 15px;
  // }

  // cart_lists
  .cart_lists {
    padding: 30px;
    // width: calc(100% - 330px);
    flex: 0 0 calc(100% - 330px);

    @include size(md-device) {
      width: 100%;
      flex: 0 0 100%;
    }

    @include size(sm-device) {
      width: 100%;
      flex: 0 0 100%;
      padding: 20px;
    }

    .cart_title {
      // display: flex;
      align-items: center;
      font-size: 22px;
      font-weight: 400;
      height: 50px;
      font-family: "Roboto", sans-serif;

      // margin-left: 2.2em;
      @include size(sm-device) {
        // margin-left: 8em;
      }
    }

    .cart_list_wrap {
      padding: 25px 40px;
      overflow: hidden;

      @include size(sm-device) {
        padding-left: 0px;
        padding-right: 0px;
      }

      .cart_responsive {
        overflow-x: auto;
        overflow-y: auto;
        max-height: 380px;

        @include size(md-device) {
          max-height: 380px;
        }

        @include size(sm-device) {
          // max-height: 800px;
        }

        &::-webkit-scrollbar {
          width: 4px;
          height: 4px;
        }

        &::-webkit-scrollbar-thumb {
          background-color: #ddd;
        }

        &::-webkit-scrollbar-track {
          background-color: #eee;
        }

        .tr_item {
          display: grid;
          grid-template-columns: 80px 3fr 1fr 1fr 120px 50px;
          box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -30px;
          margin-bottom: 15px;
          transition: all 0.3s linear;
          position: relative;
          transform: scale(1);

          // height: 7.5em;
          @for $i from 1 through 50 {
            &:nth-last-child(#{$i}) {
              animation: listshow linear;
              animation-duration: 1000ms - 180ms * $i;
              transform-origin: top;

              @keyframes listshow {
                0% {
                  opacity: 0;
                  transform: scaleY(0);
                  transform-origin: top;
                }

                50% {
                  transform: scaleY(0);
                }

                100% {
                  opacity: 1;
                  transform: scaleY(1);
                }
              }
            }
          }

          @include size(sm-device) {
            grid-template-columns: 80px auto 80px auto;
          }

          &::after {
            content: "";
            position: absolute;
            left: 0px;
            bottom: 0px;
            height: 2px;
            background-color: #d9d9d9;
            width: 0px;
            margin: auto;
          }

          @keyframes line {
            0% {
              width: 0px;
            }

            100% {
              width: calc(100% - 50px);
            }
          }

          &:hover {
            transform: scale(1);

            &::after {
              width: calc(100% - 50px);
              animation: line 0.5s linear;
            }
          }

          .td_item {
            padding: 10px;
            display: flex;
            flex-direction: column;
            align-items: baseline;
            justify-content: center;
            font-weight: 400;
            font-size: 16px;
            font-family: "Roboto", sans-serif;
            color: #666;
            transition: all 0.3s linear;

            @include size(sm-device) {
              padding: 0px;
              align-items: center;
            }

            .itemSize {
              font-family: "Roboto", sans-serif !important;
            }

            &.item_img {
              @include size(sm-device) {
                grid-row-start: 1;
                grid-row-end: 3;
              }

              img {
                height: 80px;
                width: 80px;
                max-width: 120%;
              }
            }

            &.item_name {
              @include size(sm-device) {
                grid-row-start: 1;
                grid-row-end: 2;
                grid-column-start: 2;
                grid-column-end: 5;
                width: 100%;
              }

              .main {
                font-size: 16px;
                font-family: "Roboto", sans-serif;
              }

              .sub {
                font-size: 12px;
                font-family: "Roboto", sans-serif;
                font-weight: 700;
              }
            }

            &.item_price {
              padding: 0 !important;
              font-family: "Roboto", sans-serif;
              margin-left: -10px;
              font-weight: 600;
              color: black;

              label {
                margin: auto;
              }
            }

            &.item_remove {
              background-color: transparent;
              color: #008392;
              cursor: pointer;

              @include size(sm-device) {
                position: absolute;
                right: 0px;
                top: 0px;
              }

              span {
                font-size: 18px;
                opacity: 0.6;
                padding: 5px;
                cursor: pointer;
                transition: all 0.2s linear;

                &:hover {
                  opacity: 1;
                  transform: scale(1.1);
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 570px) {
  .cart_wrapper {
    width: 100%;
    max-height: 800px;
    margin-top: 100px;
    flex-wrap: wrap;
    background-color: white;
    overflow: hidden;
  }
}

//////////address
.address {
  background-color: #fff1e7;
  width: auto;
  height: auto;
  padding: 40px;
  // position: absolute;
  position: relative;
  margin-top: 75px;

  right: 0;
  box-shadow: rgba(0, 0, 0, 0.1) -5px -3px 3px 1px;
}

@media only screen and (max-width: 570px) {
  .address {
    background-color: #fff1e7;
    width: auto;
    height: auto;
    padding: 40px;
    // position: absolute;
    margin-top: 0px !important;
    position: relative;
    right: 0;
    box-shadow: rgba(0, 0, 0, 0.45) -15px 0px 20px 1px;
  }
}

@media (max-device-width: 1024px) {
  .address {
    width: 100%;
    // max-width: 400px;
  }
}

.MuiStepIcon-root.MuiStepIcon-active {
  color: black !important;
}

.MuiStepIcon-root.MuiStepIcon-completed {
  color: black !important;
}
