.Link {
  color: black;
  text-decoration: none;
}

.Link:hover {
  color: deepskyblue;
  cursor: pointer;
  text-decoration: none;
}

@media only screen and (max-width: 768px) {
  .footerItems {
    display: block !important;
  }
}

@media only screen and (max-width: 768px) {
  .Insta {
    align-content: center !important;
    display: block !important;
  }
  .facebook {
    display: block !important;
  }
}

@media only screen and (max-width: 767px) {
  .FAQ {
    padding-right: 0px !important;
  }
}

@media (min-width: 600px) {
  .MuiGrid-grid-sm-4 {
    flex-grow: 0;
    max-width: 100% !important;
    flex-basis: 33.333333%;
}
}

.MuiGrid-spacing-xs-5 {
  width: calc(100%);
  margin: -20px;
}