.addtitle {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 40px;
}
.Next {
  padding: 10px 35px;
  width: 100% !important;
  margin-top: 2em;
  color: #fff1e6;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  font-size: 18px;
  font-family: sans-serif;
  transition: all 0.5s;
  text-decoration: none;
  list-style: none;
  z-index: 5;
}

.Next:before {
  width: 100%;
  height: 100%;
  content: "";
  margin: auto;
  position: absolute;
  top: 0%;
  left: 0%;
  background: black;
  transition: all 0.5s;
  z-index: -1;
}
.Next:after {
  width: 100%;
  height: 100%;
  content: "";
  margin: auto;
  position: absolute;
  top: 0%;
  left: 0%;
  background: black;
  transition: all 0.5s;
  z-index: -1;
}
.Next:hover {
  color: black;
  background-color: white;
}
.Next:hover:before {
  transform: rotateX(90deg);
}
.Next:hover:after {
  transform: rotateY(90deg);
}
.arrowLeft {
  width: 30px;
  height: 30px;
  position: absolute;
  transform: translate(-0.8em, -0.1em);
  color: rgb(0, 0, 0);
  cursor: pointer;

  transition: "all 1s ease";
}

.arrowLeft:hover {
  color: rgba(158, 91, 91, 0.822);
}
