.titlecms {
  font-size: 20px !important;
  margin-left: 1rem;
}
.titlecms1 {
  font-size: 20px !important;
  margin-right: 1rem;
  float: right;
}
.titlecms1:hover {
  color: #a2d7e9;
}
.btn-primary {
  background-color: #a2d7e9 !important;
  border-color: #a2d7e9 !important;
}
.btn-danger {
  color: black !important;
  background-color: transparent;
  border-color: transparent;
}
.titleh2 {
  font-size: 15px !important;
  padding-top: 5px;
}
