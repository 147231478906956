.prodname {
  font-size: 20px !important;
}



@media only screen and (max-width: 600px) {
  #product_id {
    text-align: center !important;
  }
  .priceui {
    font-size: 20px !important;
  }
  /* #no_of_reviews{
    text-align: center !important;
    } */
  .ratingui {
    text-align: center !important;
  }
  .plusresponsive {
    margin-left: 50px !important;
  }
  .addtocartbtn {
    border: none !important;
    cursor: pointer !important;
    outline: none !important;
  }
}

.addtocartbtn {
  background-color: black;
  border: none;
  padding: 0.5rem 2rem;
  margin-left: 4px !important;
}

@media only screen and (max-width: 400px) {
  .addtocartbtn {
    position: relative;
    padding: 0.5rem 2rem;
    margin-top: 10px;
    /* margin: 0 auto !important; */
  }
}

#AddToCart:hover {
  background-color: #e7f2f7;
  color: black !important;
}

.swatch :checked {
  transform: scale(2.4) !important;
  background-color: #e7f2f7 !important;
  color: black !important;
}
