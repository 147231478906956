.carousel {
  display: block !important;
  position: relative;
  width: 100%;
  /* height: 100vh; */
}

.carouselImage {
  width: 100%;
  height: auto;
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
  width: 40px !important;
  height: 40px !important;
}

.carousel-control-next-icon {
  background-image: url("./images/next.svg") !important;
  transform: translateX(-20px);
}
.carousel-control-prev-icon {
  background-image: url("./images/prev.svg") !important;
  transform: translateX(19px);
}

.carousel-control-next {
  width: fit-content !important;
  height: fit-content !important;
  padding: 1.5rem !important;
  position: absolute !important;
  margin: auto !important;
  background-color: whitesmoke !important;
  border-radius: 50% 0 0 50% !important;
  margin-right: -2.5rem !important;
}

.carousel-control-next:hover {
  background-color: white !important;
}

.carousel-control-next::after {
  background-color: rgba(255, 255, 255, 0.5) !important;
}

.carousel-control-prev {
  width: fit-content !important;
  height: fit-content !important;
  padding: 1.5rem !important;
  position: absolute !important;
  margin: auto !important;
  background-color: whitesmoke !important;
  border-radius: 0 50% 50% 0 !important;
  margin-left: -2.5rem !important;
}

.carousel-control-prev:hover {
  background-color: white !important;
}

.carousel-control-prev::after {
  background-color: rgba(255, 255, 255, 0.5) !important;
}

.carousel-indicators {
  transform: translateY(-20px);
}
.carousel-indicators li {
  color: #d39890;
}
.carousel-indicators .active {
  color: #d39890;
}
.buttonPosition {
  transform: translateY(-20px);
}

@media only screen and (max-width: 768px) {
  .carousel {
    display: block !important;
    position: relative;
    width: 100%;

    margin-top: -10px;
    /* height: 100vh; */
  }
}

@media only screen and (max-width: 540px) {
  .carousel {
    display: block !important;
    position: relative;
    width: 100%;
    margin-top: -20px;
    /* height: 100vh; */
  }
  .carousel-control-next-icon,
  .carousel-control-prev-icon {
    width: 30px !important;
    height: 30px !important;
  }
}
