.logo {
  cursor: pointer !important;
}

.infoMain {
  color: #99795f;
}

.btnLoginn {
  background-color: black !important;
  color: white !important;
}
.btnLoginn:hover {
  background-color: #d39890 !important;
}
.btnLoginn1 {
  background-color: black !important;
  color: white !important;
  margin-right: 10px !important;
  margin-left: 10px !important;
  margin-bottom: 10px !important;
}
.btnLoginn1:hover {
  background-color: #d39890 !important;
}

.btn-dark {
  background: black;
}

.infoMain:hover {
  color: black;
  transition: 0.5s;
}

.navbar {
  background-color: rgb(255, 255, 255);
  color: black;
  /* padding: 0px !important; */
}

.items {
  display: flex;
  /* margin-top: 20px; */
  /* background-color: red; */
  /* height: 45px; */
  /* padding:10px 0px 10px 0px; */
}
.navItems:hover {
  color: #ffca9f;
}
.itemss:hover {
  color: #ffca9f;
}

.items:hover {
  color: #ffca9f;
}

.items:hover .subCat {
  /* transition: 5ms; */
  height: 200px !important;
  /* opacity: 1 !important; */
  display: block;
  color: #ffca9f;
}

.font:hover {
  color: #ffca9f;
}

.search {
  width: 100%;
  max-width: 200px;
  margin-left: 55px;
}
.react-autosuggest__input {
  border: none !important;
  border-radius: 30px !important;
}
.search {
  position: relative;
  background-color: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
  border-radius: 30px;
  max-width: 300px;
  width: 100%;
  margin-left: 200px;
  border-style: solid;
  border-width: 2px;
  border-color: #d7ebed;
  /* border: '3px',
        maxWidth: '250px',
        width: '100%', */
}

.searchIcon {
  height: 100%;
  max-width: 35px;
  width: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  border-style: solid;
  border-width: 1px;
  border-color: #f1f8fa;
  /* width: 100%; */
  /* max-width: 50px; */
  margin-left: 88.25%;
  background-color: #f1f8fa;
  border-radius: 50% !important;
}

.searchInput {
  height: 100%;
  margin-left: 20px;
}

.searchDrawer {
  position: relative;
  background-color: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
  border-radius: 30px;
  max-width: 200px;
  width: 100%;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
  border-style: solid;
  border-width: 2px;
  border-color: #d7ebed;
  /* border: '3px',
        maxWidth: '250px',
        width: '100%', */
}

.searchIconDrawer {
  height: 100%;
  max-width: 35px;
  width: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: 100%; */
  /* max-width: 50px; */
  margin-left: 161px;
  background-color: #f1f8fa;
  border-radius: 50% !important;
}

.searchInputDrawer {
  height: 100%;
  margin-left: 20px;
}

.mobileMenu {
  display: none !important;
}

.drawer {
  width: 100% !important;
  max-width: 300px !important;
}

@media only screen and (max-width: 955px) {
  .mobileMenu {
    display: block !important;
  }
  .items {
    display: none !important;
  }
  .itemss {
    display: none !important;
  }
  .itemsMenu {
    display: none !important;
  }
  .loginBtn {
    display: none !important;
  }
}

@media only screen and (max-width: 570px) {
  .items {
    display: none !important;
  }
  .itemss {
    display: none !important;
  }
}

@media only screen and (max-width: 570px) {
  .logo {
    margin: 0 auto !important;
    cursor: pointer !important;
  }
}

@media only screen and (max-width: 570px) {
  .search {
    margin-top: 5px;
  }
}

@media only screen and (max-width: 1200px) {
  .search {
    width: 100%;
    max-width: 200px;
    margin-left: 55px;
  }
}

@media only screen and (max-width: 1256px) {
  .search {
    width: 100%;
    max-width: 300px;
    margin-left: 30px !important;
  }
}

@media only screen and (max-width: 570px) {
  .itemsMenu {
    display: none !important;
  }
}

@media only screen and (max-width: 1296px) {
  .itemsMenu {
    margin: 0 auto !important;
    margin-top: 10px !important;
  }
}

@media only screen and (max-width: 1296px) {
  .searchIcon {
    margin-left: 88.5%;
  }
}

@media only screen and (max-width: 1296px) {
  .search {
    margin-left: 40px;
  }
}

@media only screen and (max-width: 1196px) {
  .searchIcon {
    margin-left: 88.5%;
  }
}

.loginBtn {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

@media only screen and (max-width: 570px) {
  .loginBtn {
    display: none !important;
  }
}

.itemText {
  font-size: 20px;
}

@media only screen and (max-width: 1140px) {
  .itemText {
    font-size: 15px !important;
  }
}

.subCat {
  /*display: block !important;*/
  display: none;
  margin-top: 64px;
  /* opacity: 0.5; */
  z-index: 10 !important;
  position: absolute;
  width: 300px !important;
  height: 0px !important;
  background-color: #ffca9fe8;
  /*color: gray;*/
  /*background-color: black;*/
  /* opacity: 0; */
  /* top: 0 !important; */
  transition: 2s;
}

.subList {
  background-color: white;
  color: black;
}

/* .subCat:hover {
    display: block !important;
} */

.namemobile {
  font-size: 25px !important;
  padding-left: 30px;
  padding-right: 60px;
}

.listmobile {
  font-size: 15px !important;
  padding-left: 10px;
}

a {
  text-decoration: none !important;
}

a:hover {
  color: black;
  text-decoration: none !important;
}

.listmobilelogout {
  font-size: 15px !important;
  padding-left: 10px;
  color: red;
}

.MuiDialogContent-root:first-child {
  padding-top: 0px !important;
}

.MuiDialogContent-root {
  flex: 0 0 auto !important;
  padding: 0px 0px !important;
}

.logoPosition {
  width: 70px;
  margin-left: 50px;
  z-index: 10;
  cursor: pointer;
  border: "0px solid red";
}

@media screen and (max-width: 955px) {
  .logoPosition {
    width: 70px;
    margin-left: 20vw;
  }
}

@media screen and (max-width: 720px) {
  .logoPosition {
    width: 70px;
    margin-left: 26px;
  }
}

@media screen and (max-width: 430px) {
  .logoPosition {
    width: 60px;
    margin-left: 13px;
  }
}

@media screen and (max-width: 377px) {
  .logoPosition {
    width: 60px;
    margin-left: 0px;
  }
}

@media screen and (max-width: 345px) {
  .logoPosition {
    width: 60px;
    margin-left: -5px;
  }
  .mobileMenu {
    margin-left: -15px;
    background-color: "white";
  }
}
