@mixin size($point) {
  @if $point==lg-device {
    @media (min-width: 1024px) and (max-width: 1600px) {
      @content;
    }
  } @else if $point==md-device {
    @media (min-width: 768px) and (max-width: 1023px) {
      @content;
    }
  } @else if $point==sm-device {
    @media (max-width: 767px) {
      @content;
    }
  }
}

.cart_list_wrap {
  width: 100% !important;
  // max-width: 400px;
}

.blue {
  color: blue;
}

///////////////quantity
$block: ".quantity-input";
$border: 0 solid #dbdbdb;
$border-radius: 3px;
$color--primary: #f3f3f3;
$modifier--text-color: #888;

#{$block} {
  display: flex;
  justify-content: center;
  align-items: center;

  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  border-radius: $border-radius;
  transform: scale(0.7);

  @include size(sm-device) {
    transform: scale(0.4);
    margin-left: 0em !important;
  }
}

#{$block}__modifier,
#{$block}__screen {
  user-select: none;
  outline: none;
}

#{$block}__modifier {
  padding: 0.7rem;
  width: 3rem;
  font-size: 1.5rem;

  background: #fff1e6;
  color: black;
  border: $border;
  text-align: center;
  text-shadow: 0 1px 0 rgba(#fff, 0.6);

  cursor: pointer;

  &:hover {
    background: darken($color--primary, 10%);
    color: darken($modifier--text-color, 20%);
  }

  &--left {
    border-radius: $border-radius 0 0 $border-radius;
  }

  &--right {
    border-radius: 0 $border-radius $border-radius 0;
  }
}

#{$block}__screen {
  width: 3rem;
  height: 1px;
  padding: 0.7rem;
  font-size: 20px;

  border: 0;
  border-top: $border;
  border-bottom: $border;
  text-align: center;
}

.item_name {
  padding-right: 100px !important;
  max-width: 10px !important;
}

// @media only screen and (max-width: 800px) {
// .cart_wrapper .cart_lists .cart_list_wrap .cart_responsive .tr_item {
//   display: grid;
//   grid-template-columns: 5fr 5fr 1fr 1fr 1fr 1fr 2fr;

// }

.item_name {
  margin-left: 40px !important;
}
.item_img {
  width: 100% !important;
  margin-right: 54px !important;
  z-index: 10;
}

//}
// @media only screen and (min-width: 800px) {
//   .cart_wrapper .cart_lists .cart_list_wrap .cart_responsive .tr_item {
//     display: grid;
//     grid-template-columns: 80px 80px 80px 80px 180px 120px 120px;

//   }

//}

@media only screen and (max-width: 1024px) {
  .subtotal {
    margin-left: 1rem !important;
  }
}
@media only screen and (max-width: 800px) {
  .bonjour {
    transform: scale(0.9) !important;
    // box-shadow: 0 0 0 0 !important;
    box-shadow: 0 0px 0px rgb(0, 0, 0 / 10%) !important;
  }
}

.bonjour {
  // transform: scale(0.9) !important;
  // box-shadow: 0 0 0 0 !important;
  box-shadow: 0 0px 0px rgb(0, 0, 0 / 10%) !important;
}
