.backtotop {
    position: fixed;
    display: flex;
    bottom: 10px;
    left: 10px;
    background: rgb(255, 202, 159);
    z-index: 10;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.backtotop:hover {
    background: rgb(245, 189, 143);
    animation: backToTopAnim 0.5s ease-in-out;
}

@keyframes backToTopAnim {
    from {
        transform: translateY(0px);
    }
    25% {
        transform: translateY(-5px);
    }
    50% {
        transform: translateY(-10px);
    }
    75% {
        transform: translateY(-5px);
    }
    to {
        transform: translateY(0px);
    }
}
